import React from "react";
import { TextField, Box, Grid, Typography, Paper } from "@mui/material";
import {
  CHECKBOX,
  CITY,
  COUNTRY,
  EMAIL,
  PHONE,
  PRODUCT_CATEGORY,
  SELECT,
  SOCIAL,
  STATE,
  TEL,
  TEXT,
} from "./constants";
import FormWidget from "./FormWidget";
import { LoadingButton } from "@mui/lab";
const formConfig = {
  sections: [
    {
      title: "Basic Information",
      fields: [
        {
          label: "Title",
          required: true,
          type: SELECT,
          id: "title",
          list: ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr.", "Prof."],
        },
        { label: "First Name", required: true, id: "firstName", type: TEXT },
        { label: "Last Name", required: true, id: "lastName", type: TEXT },
        { label: "Email", required: true, id: "email", type: EMAIL },
        {
          label: "Designation",
          required: true,
          type: SELECT,
          id: "designation",
          list: [
            "Chairman",
            "Managing Director",
            "CEO",
            "President",
            "Country Head",
            "Executive Director",
            "Vice President",
            "Sr. Vice President",
            "CTO",
            "COO",
            "CFO",
            "General Manager",
            "Sr. Director",
            "Director",
            "Deputy Director",
            "General Manager",
            "Manager",
            "Production Head",
            "Technician",
            "Engineer",
            "Machine Operator",
            "Production/ Plant/ Technical Head",
            "Trainees",
            "Consultant",
            "Executive",
            "Partner",
            "Advisor",
            "Programmer",
            "Contractor",
            "Engineer",
          ],
        },
        { label: "Mobile No.", required: true, id: "mobileNo", type: PHONE },
      ],
    },
    {
      title: "Company Information",
      fields: [
        {
          label: "Company Name",
          required: true,
          id: "companyName",
          type: TEXT,
        },
        { label: "Address", required: true, id: "address", type: TEXT },
        { label: "Country", required: true, id: "country", type: COUNTRY },
        {
          label: "State/Region",
          required: false,
          id: "stateRegion",
          type: STATE,
        },
        { label: "City", required: false, id: "city", type: CITY },
        { label: "Pin/Zip", required: false, id: "pinZip", type: TEXT },
        {
          label: "Telephone No.",
          required: false,
          id: "telephoneNo",
          type: TEL,
        },
        { label: "Website", required: false, id: "website", type: TEXT },
      ],
    },
    {
      title: "Professional Information",
      fields: [
        {
          label: "Your Industry sector",
          required: true,
          type: SELECT,
          id: "industrySector",
          wd: 6,
          list: [
            "Academia and Technical Institutions",
            "Aerospace / Civil Aviation",
            "Agriculture",
            "Aluminium Extrusion",
            "Auto Components",
            "Automation",
            "Automobile",
            "Biotechnology",
            "Capital Goods",
            "Chemicals",
            "Consumer Durables",
            "Defence and Railway Units",
            "Die & Mould",
            "Earthmoving Equipment",
            "Electrical and Electronics",
            "Embassies / High Commission",
            "Engineering Services",
            "Food Processing and Diary Equipment",
            "General Engineering",
            "Government Department",
            "Healthcare",
            "IT Industry",
            "Industrial Machinery",
            "Industry 4.0",
            "Infrastructure & Earth Moving Equipment",
            "Job Shops",
            "Logistics",
            "Machine Tools & Other Metal working Industries",
            "Maintenance & Services",
            "Material Handling",
            "Measuring Instruments & Gauges",
            "Medical Engineering",
            "Office Equipment and automation",
            "Oil & Gas equipment",
            "Paper Industries & Products",
            "Pharma Equipment",
            "Plant and Machinery",
            "Plastics Processing",
            "Polymers(Rubber Plastic PVC Resin Poly)",
            "Public Sector",
            "Renewable Energy",
            "Research and development organisations",
            "Space and Nuclear",
            "Telecom Equipment",
            "Textile Machinery & Products",
            "Tractors and Farm Equipment",
            "White and Brown Goods Manufacturers",
          ],
        },
        {
          label: "Department",
          required: true,
          type: SELECT,
          id: "department",
          wd: 6,
          list: [
            "Business Management",
            "Distributor/Channel Partner/Agent",
            "Engineer/Controller/Technician",
            "Finance/Financial Management",
            "Human Resources/Administration",
            "IT",
            "Logistics/Material Management/Warehousing & Transportation",
            "Management",
            "Operations",
            "Operator/Supervisor/Machinist",
            "Procurement",
            "Production/Manufacturing",
            "Programmer/Analyst/Planner",
            "Quality",
            "R&D/Design",
            "Safety",
            "Sales/Marketing/Servicing",
            "Training",
          ],
        },
        {
          label: "How did you find out about this event",
          required: true,
          type: SELECT,
          id: "eventDiscovery",
          wd: 12,
          list: [
            "Invitation from the Exhibitor",
            "Invitation from the Organizer",
            "Newspaper Advt.",
            "Social Media / Online Ads",
            "Outdoor Ads",
            "Trade Publications",
          ],
        },
      ],
    },
    {
      title: "You will be visiting...",
      requied: true,
      fields: [
        { label: "IMTEX FORMING", type: CHECKBOX, id: "imtexForming" },
        {
          label: "DIGITAL MANUFACTURING",
          type: CHECKBOX,
          id: "digitalManufacturing",
        },
        { label: "TOOLTECH", type: CHECKBOX, id: "toolTech" },
      ],
    },
    {
      title: "Product Categories",
      requied: true,
      fields: [
        {
          label: "Add the product categories that interest you",
          required: true,
          type: PRODUCT_CATEGORY,
          id: "productCategories",
          values: [],
          wd: 12,
        },
      ],
    },
    {
      title: "Social Links",
      fields: [
        {
          label: "Please add social media links here",
          required: false,
          type: SOCIAL,
          wd: 12,
          id: "socialLinks",
          values: { twitter: "", fb: "", ln: "", in: "" },
        },
      ],
    },
  ],
};

const FormComponent = () => {
  return (
    <Box>
      {formConfig.sections.map((section, index) => (
        <Box key={index} mt={index !== 0 ? 2 : 0}>
          <Section title={section.title} fields={section.fields} />
        </Box>
      ))}
    </Box>
  );
};

const Section = ({ title, fields }) => {
  return (
    <Paper elevation={0} sx={{ borderRadius: 1, overflow: "hidden" }}>
      <Box p={1} pl={2} bgcolor="#397082" color="white">
        <Typography fontWeight={"bold"}>{title}</Typography>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.wd ? field.wd : 4} key={index}>
              <FormWidget form={field} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};
export default FormComponent;
