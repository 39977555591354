import { useEffect, useState } from 'react';

export interface Country {
  name: string;
  code: string;
  flag: string;
}

const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countryData: Country[] = data.map((country: any) => ({
          name: country.name.common,
          code: country.cca3,
          flag: country.flags.png, // URL for the flag image
        }));

        // Sort the countryData array alphabetically by country name
        const sortedCountryData = countryData.sort((a: Country, b: Country) => a.name.localeCompare(b.name));
        
        setCountries(sortedCountryData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return { countries, loading };
};

export default useCountries;
