import React, { useState } from "react";
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { CHECKBOX, CITY, COUNTRY, EMAIL, PHONE, PRODUCT_CATEGORY, SELECT, SOCIAL, STATE, TEL, TEXT, WidgetType} from "./myconst";
import { MuiTelInput } from "mui-tel-input";
import CountrySelect from "./countrystatecity/countryselect";
import StateSelect from "./countrystatecity/stateselect";
import CitySelect from "./countrystatecity/cityselect";
import ProductCategory from "./ProductCategory";
import SocialMediaForm from "./SocialMediaForm";
interface Form {
  label: string;
  required?: boolean;
  type: WidgetType;
  list?: any[];
}

interface FormWidgetProps {
  form: Form;
}

const FormWidget: React.FC<FormWidgetProps> = ({ form }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [categories, setCategories] = useState()
  return ( 
    <Box>
      {form.type === TEXT || form.type === EMAIL || form.type === TEL ? (
        <TextField size="small" label={ <span>
      {form.label}
      {form.required && <span style={{ color: 'red' }}> *</span>}
    </span>} type={form.type} fullWidth/>
      ) : form.type === CHECKBOX ? (
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={form.label}
        />
      ) : form.type === SELECT? (
        <Autocomplete
              size="small"
              options={form.list || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      {form.label}
                      {form.required && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  variant="outlined"
                />
              )}
              value={selectedOption}
              onChange={(event, newValue) =>
                setSelectedOption(newValue === null ? "" : newValue)
              }
            />
      ): form.type === PHONE?  <MuiTelInput
      fullWidth
      size="small"
      label={
        <span>
          {form.label}
          {form.required && <span style={{ color: 'red' }}> *</span>}
        </span>
      }
      defaultCountry="IN"
      value={phoneValue}
      onChange={(newValue)=>{
          setPhoneValue(newValue)
      }}
    />
:form.type === COUNTRY? <CountrySelect />:

form.type === STATE? <StateSelect countryCode={'IN'} label="State"/>:
form.type === CITY? <CitySelect countryCode={'IN'} stateCode={'KA'} label="City"/>:
form.type === PRODUCT_CATEGORY? <ProductCategory/> : 
form.type === SOCIAL? <SocialMediaForm />:(
        <></>
      )}
    </Box>
  );
};

export default FormWidget;