import React, { useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MainCategory, PRODUCT_CATEGORIES, Subcategory } from "./myconst";

const MAX_SELECTIONS = 10;

const ProductCategory: React.FC = () => {
  const [categoriesA, setCategoriesA] = useState<string[]>([]);

  const deriveCheckedSubcategories = (selectedCategories: string[]) => {
    return selectedCategories.reduce((acc, cat) => {
      const [code, _] = cat.split(" - ");
      acc[code] = true;
      return acc;
    }, {} as { [key: string]: boolean });
  };

  const [openMainCategories, setOpenMainCategories] = useState<{
    [key: string]: boolean;
  }>({});
  const [openCategories, setOpenCategories] = useState<{
    [key: string]: boolean;
  }>({});
  const [checkedSubcategories, setCheckedSubcategories] = useState<{
    [key: string]: boolean;
  }>(deriveCheckedSubcategories(categoriesA));

  const toggleMainCategory = (code: string) => {
    setOpenMainCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleCategory = (code: string) => {
    setOpenCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleSubcategory = (code: string) => {
    setCheckedSubcategories((prev) => {
      const isCurrentlyChecked = prev[code] || false;
      const selectedCount = Object.values(prev).filter(Boolean).length;

      if (!isCurrentlyChecked && selectedCount >= MAX_SELECTIONS) {
        alert("You have already selected the maximum of 10 subcategories.");
        return prev; // Do nothing if limit is reached
      }

      return { ...prev, [code]: !isCurrentlyChecked };
    });
  };

  // Group data by MainCategory and then by Category
  const groupedData: { [key: string]: MainCategory } = PRODUCT_CATEGORIES.reduce(
    (acc, curr) => {
      if (!acc[curr.MainCategory.code]) {
        acc[curr.MainCategory.code] = { ...curr.MainCategory, categories: {} };
      }
      if (!acc[curr.MainCategory.code].categories[curr.Category.code]) {
        acc[curr.MainCategory.code].categories[curr.Category.code] = {
          ...curr.Category,
          subcategories: [],
        };
      }
      acc[curr.MainCategory.code].categories[
        curr.Category.code
      ].subcategories.push(curr.Subcategory);
      return acc;
    },
    {} as { [key: string]: MainCategory }
  );

  const subcategoryCodeToData: { [key: string]: Subcategory } = {};

  Object.values(groupedData).forEach((mainCategory) => {
    Object.values(mainCategory.categories).forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        subcategoryCodeToData[subcategory.code] = {
          code: subcategory.code,
          name: subcategory.name,
        };
      });
    });
  });

  useEffect(() => {
    const selectedSubcategories = Object.keys(checkedSubcategories)
      .filter((code) => checkedSubcategories[code]) // Only get checked codes
      .map((checkedCode) => {
        const data = subcategoryCodeToData[checkedCode];
        return `${data?.code} - ${data?.name}`; // Concatenate code and name with a hyphen
      });
    if (selectedSubcategories.length !== categoriesA.length) {
      setCategoriesA(selectedSubcategories);
    }
  }, [checkedSubcategories]);

  return (
    <Box>
      <Typography mb={1}> Maximum 10 selections allowed</Typography>
      <List
        style={{
          border: "1px solid #E0E0E0",
          borderRadius: 5,
          overflow: "hidden",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {Object.values(groupedData).map((mainCategory) => (
          <React.Fragment key={mainCategory.code}>
            {/* Main Category */}
            <ListItemButton
              onClick={() => toggleMainCategory(mainCategory.code)}
              style={{
                backgroundColor: "#F7F7F7",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <ListItemText primary={<>{mainCategory.name}</>} />
              {openMainCategories[mainCategory.code] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            {/* Categories */}
            <Collapse
              in={openMainCategories[mainCategory.code]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {Object.values(mainCategory.categories).map((category) => (
                  <React.Fragment key={category.code}>
                    <ListItemButton
                      onClick={() => toggleCategory(category.code)}
                      style={{
                        paddingLeft: 30,
                        backgroundColor: "#E9E9E9",
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            {category.code} - {category.name}
                          </>
                        }
                      />
                      {openCategories[category.code] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>

                    {/* Subcategories */}
                    <Collapse
                      in={openCategories[category.code]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {category.subcategories.map((subcategory) => (
                          <ListItemButton
                            key={subcategory.code}
                            role={undefined}
                            dense
                            onClick={() => toggleSubcategory(subcategory.code)}
                            style={{
                              paddingLeft: 60,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor = "#F0F0F0")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor = "")
                            }
                          >
                            <Checkbox
                              size="small"
                              edge="start"
                              checked={
                                checkedSubcategories[subcategory.code] || false
                              }
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                            />
                            <ListItemText
                              primary={`${subcategory.code} - ${subcategory.name}`}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ProductCategory;
