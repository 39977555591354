import { useEffect, useState } from 'react';

export interface State {
  name: string;
  code: string;
}

const useStates = (countryCode: string | null) => {
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!countryCode) return;

    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://apis.expoplanner.in/visitor/get-states-of-country/${countryCode}`);
        const data = await response.json();
        const stateData = data.map((state: any) => ({
          name: state.name,
          code: state.isoCode,
        }));
        setStates(stateData);
      } catch (error) {
        console.error('Failed to fetch states:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchStates();
  }, [countryCode]);

  return { states, loading };
};

export default useStates;
