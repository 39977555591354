import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Box, TextField, Button, InputAdornment, Stack, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

interface SocialMediaLinks {
  twitter: string;
  facebook: string;
  linkedin: string;
  instagram: string;
}

const SocialMediaForm: React.FC = () => {
  const [links, setLinks] = useState<SocialMediaLinks>({
    twitter: '',
    facebook: '',
    linkedin: '',
    instagram: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLinks((prevLinks) => ({
      ...prevLinks,
      [name]: value,
    }));
  };

  return (
    <Stack direction={'column'} gap={2}>
        <Typography>Please add your social media link here</Typography>
    <TextField
      size="small"
      label="Twitter/X"
      placeholder='Enter Link'
      name="twitter"
      variant="outlined"
      value={links.twitter}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TwitterIcon />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      size="small"
      label="Facebook"
      placeholder='Enter Link'
      name="facebook"
      variant="outlined"
      value={links.facebook}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FacebookIcon style={{ color: '#1877F2' }} />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      size="small"
      label="LinkedIn"
      name="linkedin"
      placeholder='Enter Link'
      variant="outlined"
      value={links.linkedin}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LinkedInIcon style={{ color: '#0077B5' }} />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      size="small"
      label="Instagram"
      placeholder='Enter Link'
      name="instagram"
      variant="outlined"
      value={links.instagram}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <InstagramIcon style={{ color: '#E4405F' }} />
          </InputAdornment>
        ),
      }}
    />
  </Stack>
  );
};

export default SocialMediaForm;
