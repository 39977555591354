import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { BRANDS_LOGO, EXOPLANNER_LOGO, FOOTER_BG } from './myconst';

const RegFooter: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        background: '#FBEDC7',
        padding: 2,
        borderRadius: 1,
        py: 3,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ mb: isMobile ? 2 : 0, textAlign: isMobile ? 'center' : 'left' }}>
          <img src={BRANDS_LOGO} alt="Ad" style={{ maxWidth: '480px', height: 'auto' }} />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography  variant='h6' gutterBottom><a href='https://www.imtex.in/'>www.imtex.in</a></Typography>
          <Box>
         
      <IconButton href="https://x.com/IMTEX">
        <TwitterIcon style={{ color: '#000' }} />
      </IconButton>
      <IconButton href="https://www.facebook.com/Machine.Tool.Manufacturing.Technology.Exhibition">
        <FacebookIcon style={{ color: '#1877F2' }} />
      </IconButton>
      <IconButton href="https://www.linkedin.com/company/imtex/">
        <LinkedInIcon style={{ color: '#0077B5' }} />
      </IconButton>
      <IconButton href="https://www.instagram.com/indianmachinetoolexhibition/">
        <InstagramIcon style={{ color: '#E4405F' }} />
      </IconButton>
    </Box>
        </Box>
        <Box sx={{ mt: isMobile ? 2 : 0, textAlign: isMobile ? 'center' : 'right' }}>
          <img src={EXOPLANNER_LOGO} alt="App Logo" style={{ maxWidth: '300px', height: 'auto' }} />
        </Box>
      </Container>
    </Box>
  );
};

export default RegFooter;
