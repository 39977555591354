// StateSelect.tsx
import React, { useState } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import useStates, { State } from './hooks/useStates';

interface StateSelectProps {
  countryCode: string | null;
  label: string
}

const StateSelect: React.FC<StateSelectProps> = ({ countryCode, label }) => {
    const { states, loading } = useStates(countryCode);
    const [selectedState, setSelectedState] = useState<State | null>(null);
  
    return (
      <Box>
        <Autocomplete
          size="small"
          options={states}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <span>
                  {label}
                  <span style={{ color: 'red' }}> *</span>
                </span>
              }
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={selectedState}
          onChange={(event, newValue) => setSelectedState(newValue)}
        />
      </Box>
    );
  };
  
  export default StateSelect;