import { toast } from "react-toastify";

export function toastError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message);
  } else {
    toast.error("An error occurred. Please try again.");
  }
}
export function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}
