import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormComponent from "./FormComponent";
import { ADS, FOOTER, HEADER } from "./constants";
import "./Registration.css";
import BannerSlider from "./BannerSlider";
import RegFooter from "./RegFooter";
import { LoadingButton } from "@mui/lab";
const RegistrationPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const getHeight = () => {
    if (isSmallScreen) return "150px";
    if (isMediumScreen) return "200px";
    if (isLargeScreen) return "300px";
  };
  const getVariant = () => {
    if (isSmallScreen) return "h6";
    if (isMediumScreen) return "h5";
    if (isLargeScreen) return "h4";
  };
  return (
    <Box
      sx={{
        backgroundColor: "rgb(237, 241, 242)",
        minHeight: "100vh",
        width: "100vw",
        overflow: "auto",
        position: "fixed",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: getHeight(),
          top: 0,
          position: "absolute",
          backgroundColor: "#2D545E",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
        }}
      >
        <Box maxWidth="md" sx={{ ml: "auto", mr: "auto" }} p={2}>
          <Box className="header-con-xcch" sx={{ textAlign: "center", mb: 4 }}>
            <img
              src={HEADER}
              alt="Main Header"
              style={{ width: "100%", height: "auto", marginBottom: "20px" }}
            />
            <BannerSlider />
          </Box>
          <Typography
            variant={getVariant()}
            component="h2"
            align="center"
            fontWeight={600}
            gutterBottom
            sx={{ color: "#E54E4E" }}
          >
            VISITOR REGISTRATION
          </Typography>
          <FormComponent />
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <LoadingButton
              variant="contained"
              sx={{ bgcolor: "#2D545E", width: 200 }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
        <RegFooter />
      </Box>
    </Box>
  );
};

export default RegistrationPage;
