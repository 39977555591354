import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import useCountries, { Country } from './hooks/useCountries';

const CountrySelect: React.FC = () => {
  const { countries, loading } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  useEffect(() => {
    if (!loading && countries.length > 0) {
      const india = countries.find((country) => country.code === 'IND');
      setSelectedCountry(india || null);
    }
  }, [loading, countries]);


  return (
    <Autocomplete
      size="small"
      options={countries}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.code}>
          <ListItemAvatar>
            <Avatar src={option.flag} alt={option.name} style={{ width: 24, height: 24 }} />
          </ListItemAvatar>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          
          label={
            <span>
              {"Select Country"}
              <span style={{ color: 'red' }}> *</span>
            </span>
          }
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={selectedCountry}
      onChange={(event, newValue) => setSelectedCountry(newValue)}
    />
  );
};

export default CountrySelect;
