import React, { useState } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import useCities, { City } from './hooks/useCities';

interface CitySelectProps {
  countryCode: string | null;
  stateCode: string | null;
  label: string
}

const CitySelect: React.FC<CitySelectProps> = ({ countryCode, stateCode, label }) => {
  const { cities, loading } = useCities(countryCode, stateCode);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  return (
    <Box>
      <Autocomplete
        size="small"
        options={cities}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <span>
                {label}
                <span style={{ color: 'red' }}> *</span>
              </span>
            }
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        value={selectedCity}
        onChange={(event, newValue) => setSelectedCity(newValue)}
      />
    </Box>
  );
};

export default CitySelect;