export const SELECT = "select";
export const TEXT = "text";
export const PHONE = "phone";
export const TEL = "tel";
export const SOCIAL = "social";
export const EMAIL = "email";
export const COUNTRY = "country";
export const STATE = "state";
export const CITY = "city";
export const CHECKBOX = "checkbox";
export const PRODUCT_CATEGORY = "product_category";
export const BRANDS_LOGO = "https://s3.ap-south-1.amazonaws.com/expoplanner.in/footer_final_l.png"
export const FOOTER_BG = "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Footer+BG.png"
export const EXOPLANNER_LOGO = "https://s3.ap-south-1.amazonaws.com/expoplanner.in/Expoplanner.png"

// Define the type for the widget
export type WidgetType =
  | typeof SELECT
  | typeof TEXT
  | typeof PHONE
  | typeof TEL
  | typeof SOCIAL
  | typeof EMAIL
  | typeof COUNTRY
  | typeof STATE
  | typeof CITY
  | typeof CHECKBOX
  | typeof PRODUCT_CATEGORY;

export const WIDGET_LIST: WidgetType[] = [
  SELECT,
  TEXT,
  PHONE,
  TEL,
  SOCIAL,
  EMAIL,
  COUNTRY,
  STATE,
  CITY,
  CHECKBOX,
  PRODUCT_CATEGORY,
];
export interface Subcategory {
  code: string;
  name: string;
}

export interface Category {
  code: string;
  name: string;
  subcategories: Subcategory[];
}

export interface MainCategory {
  code: string;
  name: string;
  categories: { [key: string]: Category };
}

interface ProductCategory {
  MainCategory: {
    code: string;
    name: string;
  };
  Category: {
    code: string;
    name: string;
  };
  Subcategory: {
    code: string;
    name: string;
  };
}
export const PRODUCT_CATEGORIES: ProductCategory[]  = [
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.01",
      "name": "All Geared Lathe/ Centre Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.02",
      "name": "Bench Lathes-precision"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.03",
      "name": "Capstan and Turret Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.04",
      "name": "CNC Axle Journal Re-turning and Roller Burnishing Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.05",
      "name": "CNC Axle Turning Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.06",
      "name": "CNC Camshaft Turning Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.07",
      "name": "CNC CNC Multi-spindle Bar Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.08",
      "name": "CNC Crankshaft Turning Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.09",
      "name": "CNC Facing Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.10",
      "name": "CNC Heavy Duty and Roll Turning Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.11",
      "name": "CNC Lathe, Horizontal Bed"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.12",
      "name": "CNC Multi-spindle Chucking Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.13",
      "name": "CNC Oval/Polygon Turning Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.14",
      "name": "CNC Single Spindle Bar Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.15",
      "name": "CNC Single Spindle Chucking Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.16",
      "name": "CNC Turning Center"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.17",
      "name": "CNC Turn-Mill Center"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.18",
      "name": "CNC Twin Spindle Chuckers"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.19",
      "name": "CNC Vertical Turning Lathe"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.20",
      "name": "CNC Wheel Turning/ Profiling Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.21",
      "name": "Copying Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.22",
      "name": "Machine Tools for Engine re-building"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.23",
      "name": "Multi-spindle Bar Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.24",
      "name": "Multi-spindle Chucking Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.25",
      "name": "Single Spindle Bar Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.26",
      "name": "Single Spindle Chucking Automatics"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.27",
      "name": "Sliding, Surfacing and Screw Cutting Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.28",
      "name": "Watchmakers' Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A01.00",
      "name": "Turning Machines"
    },
    "Subcategory": {
      "code": "A01.29",
      "name": "Turning Machines, anyothers"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A02.00",
      "name": "CNC Multi Function Machines"
    },
    "Subcategory": {
      "code": "A02.01",
      "name": "CNC Mill-Turn Center"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A02.00",
      "name": "CNC Multi Function Machines"
    },
    "Subcategory": {
      "code": "A02.02",
      "name": "CNC Multi Function Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.01",
      "name": "Bench Type Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.02",
      "name": "Column Type Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.03",
      "name": "Coordinate Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.04",
      "name": "Deep Hole Drilling and Boring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.05",
      "name": "Gang Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.06",
      "name": "Pillar Type Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.07",
      "name": "Radial Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.08",
      "name": "Turret Head Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A03.00",
      "name": "Drilling Machines"
    },
    "Subcategory": {
      "code": "A03.09",
      "name": "Drilling Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.01",
      "name": "Bed Type Boring, and Milling Machines-Moving Column"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.02",
      "name": "Boring and Milling Machines, Gantry Type"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.03",
      "name": "Fine Boring Machines,"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.04",
      "name": "Jig Boring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.05",
      "name": "Machine Tools for Engine Re-building"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.06",
      "name": "Table Type Horizontal Boring and Milling Machines, Fixed Column"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.07",
      "name": "Vertical Boring & Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A04.00",
      "name": "Boring Machines"
    },
    "Subcategory": {
      "code": "A04.08",
      "name": "Boring and Milling Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.01",
      "name": "Bed Type Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.02",
      "name": "Copy Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.03",
      "name": "Crankshaft and Camshaft Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.04",
      "name": "Die-sinking Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.05",
      "name": "Engraving Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.06",
      "name": "Knee-type Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.07",
      "name": "Open-side Plano-milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.08",
      "name": "Plano Milling Machines, Gantry Type"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.09",
      "name": "Slot and Keyway Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.10",
      "name": "Tool Room Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.11",
      "name": "Universal Milling  Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A05.00",
      "name": "Milling Machines"
    },
    "Subcategory": {
      "code": "A05.12",
      "name": "Milling Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.01",
      "name": "High Speed Machining Centres"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.02",
      "name": "Horizontal Machining Centres"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.03",
      "name": "Machining Centres"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.04",
      "name": "Portal/Gantry Type Machining Centres"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.05",
      "name": "Vertical Machining Centres"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A06.00",
      "name": "Machining Centres"
    },
    "Subcategory": {
      "code": "A06.06",
      "name": "Machining Centres, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A07.00",
      "name": "Flexible Manufacturing Systems"
    },
    "Subcategory": {
      "code": "A07.01",
      "name": "Flexible Turning Cell"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A07.00",
      "name": "Flexible Manufacturing Systems"
    },
    "Subcategory": {
      "code": "A07.02",
      "name": "Flexible Machining Cell"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A07.00",
      "name": "Flexible Manufacturing Systems"
    },
    "Subcategory": {
      "code": "A07.03",
      "name": "Flexible Grinding Cell"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A07.00",
      "name": "Flexible Manufacturing Systems"
    },
    "Subcategory": {
      "code": "A07.04",
      "name": "Flexible Manufacturing Systems"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.01",
      "name": "Boring Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.02",
      "name": "Cylinder Boring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.03",
      "name": "Drilling Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.04",
      "name": "Facing and Centering Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.05",
      "name": "Line Boring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.06",
      "name": "Linear Indexing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.07",
      "name": "Longitudinal and Circular Dividing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.08",
      "name": "Milling Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.09",
      "name": "Multi-spindle Boring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.10",
      "name": "Multi-spindle Drilling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.11",
      "name": "Multi-spindle Tapping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.12",
      "name": "Rotary Indexing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.13",
      "name": "Special Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.14",
      "name": "Tapping Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.15",
      "name": "Transfer Lines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.16",
      "name": "Turning Units"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A08.00",
      "name": "Special Production Machines and Unit Head"
    },
    "Subcategory": {
      "code": "A08.17",
      "name": "Special Production Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.01",
      "name": "Abrasive Belt Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.02",
      "name": "Centreless Grinder"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.03",
      "name": "Creep Feed Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.04",
      "name": "Cylindrical Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.05",
      "name": "High Speed Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.06",
      "name": "Horizontal Surface Grinding Machines with Rotary Table"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.07",
      "name": "Internal Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.08",
      "name": "Jig Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.09",
      "name": "Surface Grinding"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.10",
      "name": "Vertical Spindle Surface Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A09.00",
      "name": "Grinding Machines"
    },
    "Subcategory": {
      "code": "A09.11",
      "name": "Vertical Surface Grinding, Machines with Rotary Table"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.01",
      "name": "Axle Journal Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.02",
      "name": "Bearing Race Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.03",
      "name": "Cam Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.04",
      "name": "Camshaft Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.05",
      "name": "Centre Hole Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.06",
      "name": "Crankshaft Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.07",
      "name": "Double Disc Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.08",
      "name": "Grinding Machines with Flexible Shaft"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.09",
      "name": "Oval/Polygon Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.10",
      "name": "Piston Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.11",
      "name": "Profile Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.12",
      "name": "Roll Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.13",
      "name": "Slide-way Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.14",
      "name": "Spline shaft Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.15",
      "name": "Superfinishing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.16",
      "name": "Thread Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.17",
      "name": "Valve Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A10.00",
      "name": "Special Purpose Grinding Machines"
    },
    "Subcategory": {
      "code": "A10.18",
      "name": "Grinding Machines for Special Purposes, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.01",
      "name": "Broach Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.02",
      "name": "Carbide Tool Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.03",
      "name": "Cutter and Reamer Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.04",
      "name": "Gear Cutting Tool Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.05",
      "name": "Milling Cutter Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.06",
      "name": "Multi-axes Universal Tool Grinding Machine"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.07",
      "name": "Sharpening Machines for Saw Blades"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.08",
      "name": "Shear Blade and Machine Knife Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.09",
      "name": "Single Point Cutting Tool Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.10",
      "name": "Swing-frame Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.11",
      "name": "Tap Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.12",
      "name": "Threading Die and Chaser Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.13",
      "name": "Tool Sharpening and Lapping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.14",
      "name": "Twist Drill Sharpening Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A11.00",
      "name": "Tool Grinding Machines"
    },
    "Subcategory": {
      "code": "A11.15",
      "name": "Universal Tool and Cutter Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.01",
      "name": "Bevel Gear Cutting Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.02",
      "name": "Gear Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.03",
      "name": "Gear Hobbing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.04",
      "name": "Gear Shaping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.05",
      "name": "Gear Shaving Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.06",
      "name": "Gear Tooth Rounding and Chamfering Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.07",
      "name": "Rack Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.08",
      "name": "Gear / Spline / Thread Rolling Machines (Other than for Production of Bolts & Screws)"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A12.00",
      "name": "Gear Cutting and Finishing Machines"
    },
    "Subcategory": {
      "code": "A12.09",
      "name": "Gear Cutting Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.01",
      "name": "Internal Broaching Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.02",
      "name": "Keyseating Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.03",
      "name": "Planing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.04",
      "name": "Plate Edge Planing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.05",
      "name": "Shaping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.06",
      "name": "Slotting Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.07",
      "name": "Surface Broaching Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A13.00",
      "name": "Planing, Shaping, Slotting and Broaching Machines"
    },
    "Subcategory": {
      "code": "A13.08",
      "name": "Planing, Shaping and Broaching Machines, any other"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.01",
      "name": "Abrasive Disc Cutting-off Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.02",
      "name": "Band Sawing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.03",
      "name": "Circular Sawing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.04",
      "name": "Friction Sawing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.05",
      "name": "Hack Sawing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.06",
      "name": "Sawing and Filing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A14.00",
      "name": "Sawing and Cutting-off Machines"
    },
    "Subcategory": {
      "code": "A14.07",
      "name": "Sawing and Cutting-off Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A15.00",
      "name": "Screw Cutting and Tapping Machines"
    },
    "Subcategory": {
      "code": "A15.01",
      "name": "Screw Cutting and Tapping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.01",
      "name": "Abrasive Flow Machine"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.02",
      "name": "Centreless plunge type microfinishing machine"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.03",
      "name": "Centreless Polishing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.04",
      "name": "Cylinder Honing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.05",
      "name": "Deburring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.06",
      "name": "Grinding and Polishing Drums (Tumbling Barrels)"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.07",
      "name": "Honing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.08",
      "name": "Lapping Machines, Others"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.09",
      "name": "Microfinishing machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.10",
      "name": "Polishing Machines, Abrasive Belt"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.11",
      "name": "Polishing Machines, Abrasive Discs"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.12",
      "name": "Polishing Machines, Others"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.13",
      "name": "Super finishing Vibrators"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A16.00",
      "name": "Honing, Lapping, Polishing and Deburring Machines"
    },
    "Subcategory": {
      "code": "A16.14",
      "name": "Surface Lapping and Cylindrical Lapping Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A17.00",
      "name": "Electro Erosion Machines"
    },
    "Subcategory": {
      "code": "A17.01",
      "name": "Die Sinking EDM (Spark Erosion)"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A17.00",
      "name": "Electro Erosion Machines"
    },
    "Subcategory": {
      "code": "A17.02",
      "name": "Electro-chemical Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A17.00",
      "name": "Electro Erosion Machines"
    },
    "Subcategory": {
      "code": "A17.03",
      "name": "Electrolytic Grinding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A17.00",
      "name": "Electro Erosion Machines"
    },
    "Subcategory": {
      "code": "A17.04",
      "name": "Electrolytic Metal Working Machines, Others"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A17.00",
      "name": "Electro Erosion Machines"
    },
    "Subcategory": {
      "code": "A17.05",
      "name": "Wire EDM"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A18.00",
      "name": "Robotics & Manufacturing Automation"
    },
    "Subcategory": {
      "code": "A18.01",
      "name": "Assembling Equipment and Automatic Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A18.00",
      "name": "Robotics & Manufacturing Automation"
    },
    "Subcategory": {
      "code": "A18.02",
      "name": "Assembly Machines & Lines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A18.00",
      "name": "Robotics & Manufacturing Automation"
    },
    "Subcategory": {
      "code": "A18.03",
      "name": "Industrial Robots"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A18.00",
      "name": "Robotics & Manufacturing Automation"
    },
    "Subcategory": {
      "code": "A18.04",
      "name": "Manipulators"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A19.00",
      "name": "Machines for Unconventional and other Operations"
    },
    "Subcategory": {
      "code": "A19.01",
      "name": "Ultrasonic Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A20.00",
      "name": "Machines For Marking"
    },
    "Subcategory": {
      "code": "A20.01",
      "name": "Laser Marking Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A20.00",
      "name": "Machines For Marking"
    },
    "Subcategory": {
      "code": "A20.02",
      "name": "Machines For Marking, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A21.00",
      "name": "Machine Tools for Educational Purposes"
    },
    "Subcategory": {
      "code": "A21.01",
      "name": "Machine Tools for Educational Purposes"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A22.00",
      "name": "Heating and Hardening Machines"
    },
    "Subcategory": {
      "code": "A22.01",
      "name": "Induction Hardening, Induction Heat Treatment Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A22.00",
      "name": "Heating and Hardening Machines"
    },
    "Subcategory": {
      "code": "A22.02",
      "name": "Laser Heat Treatment Machines"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A22.00",
      "name": "Heating and Hardening Machines"
    },
    "Subcategory": {
      "code": "A22.03",
      "name": "Heating and Hardening Machines, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "A",
      "name": "IMTEX 2025"
    },
    "Category": {
      "code": "A23.00",
      "name": "Organisations"
    },
    "Subcategory": {
      "code": "A23.01",
      "name": "Organisations"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.01",
      "name": "Boring Bars"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.02",
      "name": "Boring Bits"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.03",
      "name": "Boring Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.04",
      "name": "Broaching Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.05",
      "name": "Carbide-tipped Tools & Carbide Tips"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.06",
      "name": "Circular Saw Blades"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.07",
      "name": "Countersinks and Core Drills"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.08",
      "name": "Cutting Tools Coated"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.09",
      "name": "Diamond Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.10",
      "name": "Electrode Wires for WEDM"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.11",
      "name": "Electrodes for EDM"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.12",
      "name": "Embossing, Stamping and Marking Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.13",
      "name": "Engraving Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.14",
      "name": "Fine Boring Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.15",
      "name": "Gear Tools (Hobbing, Shaping & Shaving)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.16",
      "name": "Gun Drills"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.17",
      "name": "Hardmetal Inserts (Carbide Inserts)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.18",
      "name": "Inserted Blade Milling Cutter"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.19",
      "name": "Inserted Tooth Saw Blades"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.20",
      "name": "Inserts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.21",
      "name": "Knurling Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.22",
      "name": "indexable Inserts of Aluminium Oxide"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.23",
      "name": "Metal Band Saw Blades"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.24",
      "name": "Milling Cutters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.25",
      "name": "Other Tools for Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.26",
      "name": "Planing & Shaping Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.27",
      "name": "Plunge Cutting and Die Cutting Tools (Form Cutting Tools)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.28",
      "name": "Power Hacksaw Blades"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.29",
      "name": "Reamers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.30",
      "name": "Shear Knives, Machine Knives"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.31",
      "name": "Shell and Angular End Millers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.32",
      "name": "Side and Face Cutters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.33",
      "name": "Slot Millers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.34",
      "name": "Spade Drills"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.35",
      "name": "Special Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.36",
      "name": "Step Drills"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.37",
      "name": "Surface Milling Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.38",
      "name": "Taps"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.39",
      "name": "Thread Rolling Dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.40",
      "name": "Threading Dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.41",
      "name": "Threading Tools, Cutting"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.42",
      "name": "Tool Bits"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.43",
      "name": "Tools for Micromachining"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.44",
      "name": "Twist Drills & Centre Drills"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B01.00",
      "name": "Cutting Tools"
    },
    "Subcategory": {
      "code": "B01.45",
      "name": "Cutting Tools, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.01",
      "name": "Bending dies for tubes and sections"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.02",
      "name": "Bending tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.03",
      "name": "Blanking and punching dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.04",
      "name": "Car body tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.05",
      "name": "Coining dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.06",
      "name": "Composite dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.07",
      "name": "Deep drawing dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.08",
      "name": "Drawing dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.09",
      "name": "Embossing, stamping and marking tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.10",
      "name": "Fine blanking dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.11",
      "name": "Forging dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.12",
      "name": "Knurling tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.13",
      "name": "Multistage dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.14",
      "name": "Press tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.15",
      "name": "Punches and dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.16",
      "name": "Punching tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.17",
      "name": "Roll forming tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.18",
      "name": "Roller finishing and deep rolling tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.19",
      "name": "Special tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.20",
      "name": "Thread rolling tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.21",
      "name": "Tools for powder metallurgy"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.22",
      "name": "Tools for progressive presses"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.23",
      "name": "Tools for screw manufacture"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.24",
      "name": "Transfer die sets"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.25",
      "name": "Notching & Nibbling Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.26",
      "name": "Roller Burnishing Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.27",
      "name": "Standard Parts for Press Tool Moulds & Dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.28",
      "name": "Threading Tools, Forming"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B02.00",
      "name": "Forming Tools"
    },
    "Subcategory": {
      "code": "B02.29",
      "name": "Forming tools, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.01",
      "name": "Abrasive and Polishing Pastes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.02",
      "name": "Abrasive Discs and Belts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.03",
      "name": "Abrasive products with diamond"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.04",
      "name": "Bonded Abrasive Products"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.05",
      "name": "Buffing Wheels"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.06",
      "name": "Coated Abrasives"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.07",
      "name": "Deburring Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.08",
      "name": "Diamond Grinding Wheels"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.09",
      "name": "Diamond Paste"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.10",
      "name": "Dressing Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.11",
      "name": "Grinding Wheels"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.12",
      "name": "Honing, Lapping & Polishing Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.13",
      "name": "Polishing Wheels & Belts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.14",
      "name": "Superfinishing Process Materials"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.15",
      "name": "Synthetic Diamond Powder"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.16",
      "name": "Trueing and Dressing Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.17",
      "name": "Vibratory Deburring"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B03.00",
      "name": "Abrasive and Polishing Tools"
    },
    "Subcategory": {
      "code": "B03.18",
      "name": "Abrasive Tools and  Products, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B04.00",
      "name": "Hand Held Tools"
    },
    "Subcategory": {
      "code": "B04.01",
      "name": "Electric and Pneumatic Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B04.00",
      "name": "Hand Held Tools"
    },
    "Subcategory": {
      "code": "B04.02",
      "name": "Electric/Pneumatic Power Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B04.00",
      "name": "Hand Held Tools"
    },
    "Subcategory": {
      "code": "B04.03",
      "name": "Hand tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B04.00",
      "name": "Hand Held Tools"
    },
    "Subcategory": {
      "code": "B04.04",
      "name": "Manual deburring tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B04.00",
      "name": "Hand Held Tools"
    },
    "Subcategory": {
      "code": "B04.05",
      "name": "Hand held tools, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.01",
      "name": "Boring & Facing Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.02",
      "name": "Chucking Tools with Code"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.03",
      "name": "Drill Chucks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.04",
      "name": "Milling Arbors"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.05",
      "name": "Multi Spindle, Angle Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.06",
      "name": "Multispindle Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.07",
      "name": "Quick Action Fixture for Press Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.08",
      "name": "Shrink chucking equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.09",
      "name": "Sleeves"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.10",
      "name": "Slotting Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.11",
      "name": "Speed Increasers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.12",
      "name": "Tapping"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.13",
      "name": "Tool Assembly Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.14",
      "name": "Tool Breakage Detection System"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.15",
      "name": "Tool Changing Systems for Presses"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.16",
      "name": "Tool Holders"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.17",
      "name": "Tool Holders with Codes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.18",
      "name": "Tool Management System & Software"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.19",
      "name": "Tool Presetting Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.20",
      "name": "Tool Setting Instruments and Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.21",
      "name": "Tooling System, Modular"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.22",
      "name": "Tool Wear Detection System"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.23",
      "name": "Tooling System for Boring"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.24",
      "name": "Tooling System for Drilling"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.25",
      "name": "Tooling System for Grinding"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.26",
      "name": "Tooling System for Milling"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.27",
      "name": "Tooling System for Turning"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.28",
      "name": "Turret Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B05.00",
      "name": "Tooling Systems"
    },
    "Subcategory": {
      "code": "B05.29",
      "name": "Tooling Systems, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.01",
      "name": "Chucking systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.02",
      "name": "Chucks, others"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.03",
      "name": "Clamping devices; other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.04",
      "name": "Clamping Elements"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.05",
      "name": "Collet Chucks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.06",
      "name": "Elements for Jigs & Fixtures"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.07",
      "name": "Expanding Mandrels"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.08",
      "name": "Fixture plates"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.09",
      "name": "Hydraulic Clamping Device"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.10",
      "name": "Lathe Chucks, Hand Operated"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.11",
      "name": "Lathe Chucks, Power Operated"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.12",
      "name": "Machine Vices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.13",
      "name": "Magnetic Chucks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.14",
      "name": "Magnetic Plates"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.15",
      "name": "Vacuum Fixing Plate"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B06.00",
      "name": "Workholding"
    },
    "Subcategory": {
      "code": "B06.16",
      "name": "Workholding, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.01",
      "name": "Automated Guided Vehicles"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.02",
      "name": "Automated Storage and Retrieval Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.03",
      "name": "Components To lnterlink Separate Production Stages"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.04",
      "name": "Conveying Equipment and Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.05",
      "name": "Palletizing Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.06",
      "name": "Rail Guided Vehicles"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B07.00",
      "name": "Automation for Storage & Transport"
    },
    "Subcategory": {
      "code": "B07.07",
      "name": "Automation for Storage & Transport, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.01",
      "name": "Changing System for Grippers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.02",
      "name": "End Effectors and Actuators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.03",
      "name": "Grippers and Tools for Robots"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.04",
      "name": "Gripping Tools and Automatic Fixtures"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.05",
      "name": "Loading & Feeding for Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.06",
      "name": "Manipulators for Aligning and Positioning of Work pieces"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.07",
      "name": "Manipulators for Warehousing, Input and Delivery of Workpieces"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.08",
      "name": "Tool Changing Systems for Presses"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B08.00",
      "name": "Components for Robotics & Automation"
    },
    "Subcategory": {
      "code": "B08.09",
      "name": "Units for Assembly Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.01",
      "name": "Bar Feeders"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.02",
      "name": "Boring Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.03",
      "name": "Centres, Tailstock for Lathes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.04",
      "name": "Cleaning and Degreasing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.05",
      "name": "Copying Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.06",
      "name": "Cross Slide Tables (NC Controlled)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.07",
      "name": "Dividing Heads and Tables"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.08",
      "name": "Oil Recovery Equipment  (Chip Separator)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.09",
      "name": "Package Presses for Chips"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.10",
      "name": "Pneumatic and Hydraulic Accessories"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.11",
      "name": "Process water treatment for superfinishing systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.12",
      "name": "Spherical Turning Attachments"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.13",
      "name": "Steady & Follow rests"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.14",
      "name": "Steady rests self-centering"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.15",
      "name": "Swarf Breaker"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.16",
      "name": "Taper Turning Attachment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.17",
      "name": "Threading Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.18",
      "name": "Tilting Tables"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.19",
      "name": "Accessories, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.20",
      "name": "Air Bearing Based Linear Guiding Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.21",
      "name": "Air Heat Exchangers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.22",
      "name": "Gear Units"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.23",
      "name": "Grinding Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.24",
      "name": "Grinding Spindles"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.25",
      "name": "Grinding Wheel Trueing, Dressing and  Forming Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.26",
      "name": "High Frequency Spindles for Grinding"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.27",
      "name": "High Pressure Coolant Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.28",
      "name": "Hydraulic Fluid Power Controls & Components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.29",
      "name": "Hydrostatic/Aerostatic Bearings"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.30",
      "name": "Main Spindles for Turning, Milling & Boring and  Milling Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.31",
      "name": "Motor Spindle Units for Turning & Milling"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.32",
      "name": "Panel Coolers and Panel AC"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.33",
      "name": "Pneumatic Fluid Power Transmissions/Controls & Components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.34",
      "name": "Positioning Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.35",
      "name": "Precision Actuators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.36",
      "name": "Refrigerating Units"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.37",
      "name": "Swarf Conveyor"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.38",
      "name": "Through Spindle Coolant System"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.39",
      "name": "Tool Magazines and Tool Changers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.40",
      "name": "Tool Turret"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.41",
      "name": "Unit Heads"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.42",
      "name": "Water Heat Exchangers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B09.00",
      "name": "Attachments, Accessories & Sub-systems"
    },
    "Subcategory": {
      "code": "B09.43",
      "name": "Sub-system, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.01",
      "name": "Anti-friction Bearings including Spindle/Ball Screw"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.02",
      "name": "Anti-friction Linear Slides"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.03",
      "name": "Anti-vibration Mounts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.04",
      "name": "Ball Screws & Planetary Roller Screws"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.05",
      "name": "Beds and Parts of Machines by Polymer Concrete & Others"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.06",
      "name": "Brake"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.07",
      "name": "Clutches"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.08",
      "name": "Couplings"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.09",
      "name": "Drive Belts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.10",
      "name": "Hard Rock Machine Frames"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.11",
      "name": "Levelling Jacks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.12",
      "name": "Linear Guide Rails"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.13",
      "name": "Machine Lamps"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.14",
      "name": "Slideway and Leadscrew Covers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.15",
      "name": "Springs"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B10.00",
      "name": "Mechanical Elements and Components"
    },
    "Subcategory": {
      "code": "B10.16",
      "name": "Mechanical Elements and Components, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.01",
      "name": "Actuators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.02",
      "name": "Air Preparation (D-F-R)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.03",
      "name": "Assemblies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.04",
      "name": "Cylinders"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.05",
      "name": "Filters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.06",
      "name": "Fittings, Connections"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.07",
      "name": "Gaskets, O-Rings and Seals"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.08",
      "name": "High Pressure Lines and Hoses, Flexible Tubing"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.09",
      "name": "Hydraulic & Pneumatic Valves"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.10",
      "name": "Hydropneumatic Accumulators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.11",
      "name": "Motors"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.12",
      "name": "Pneumatic Components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.13",
      "name": "Pneumatic & Oil Pneumatic Jacks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.14",
      "name": "Positining Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.15",
      "name": "Power Packs"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.16",
      "name": "Pressure Reducers and Intensifiers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.17",
      "name": "Pumps"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.18",
      "name": "Relieving Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.19",
      "name": "Rotary Unions"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B11.00",
      "name": "Hydraulic and Pneumatic Systems and Elements"
    },
    "Subcategory": {
      "code": "B11.20",
      "name": "Hydraulic and Pneumatic System & Elements, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.01",
      "name": "Cable Duct Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.02",
      "name": "Cable Holder Chains"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.03",
      "name": "Control Devices and Switches"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.04",
      "name": "Converters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.05",
      "name": "Cooling Fans"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.06",
      "name": "Electric Control Panels, Cubicles"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.07",
      "name": "Electric Motors"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.08",
      "name": "Electrical & Electronic Components and Assemblies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.09",
      "name": "Flexible Protective Hose"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.10",
      "name": "Limit Switches"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.11",
      "name": "Machine Monitoring Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.12",
      "name": "Metering and Counting Instrument & Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.13",
      "name": "Power Electronics"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.14",
      "name": "Rectifiers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.15",
      "name": "Sensors and Transducers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.16",
      "name": "Signal Processing Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.17",
      "name": "Switchgear and Control Gear"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.18",
      "name": "UPS & Inverters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.19",
      "name": "Voltage Stabilizers & Conditioners"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B12.00",
      "name": "Electrical & Electronic Equipment"
    },
    "Subcategory": {
      "code": "B12.20",
      "name": "Electrical & Electronic Equipment for Machine Tools, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.01",
      "name": "Press feeding systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.02",
      "name": "Decoilers and Straighteners"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.03",
      "name": "Wire feeding systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.04",
      "name": "Automation"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.05",
      "name": "Sheet handling systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.06",
      "name": "Scrap conveyor systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.07",
      "name": "Robotic manipulators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.08",
      "name": "Loading & Material Feeding Equipment for Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B13.00",
      "name": "Feeding Systems"
    },
    "Subcategory": {
      "code": "B13.09",
      "name": "Feeding systems, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.01",
      "name": "Balancing Machine"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.02",
      "name": "Corrosion Testing Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.03",
      "name": "Deep-drawing Testers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.04",
      "name": "Electronic Unbalance Measuring and Control Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.05",
      "name": "Falling Weight Testing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.06",
      "name": "Gear Testing Machines and Apparatus"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.07",
      "name": "Hardness Testing Machine"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.08",
      "name": "Machines and Apparatus for Non-destructive Metal Tests"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.09",
      "name": "Metallographic Testing Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.10",
      "name": "Other Testing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.11",
      "name": "Tensile Testing Machine"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.12",
      "name": "Testing Machines impact (Pendulum Type)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.13",
      "name": "Testing Machines, Spring Type"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.14",
      "name": "Torsion Testing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.15",
      "name": "Ultrasonic Testing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.16",
      "name": "Universal Testing Machine"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.17",
      "name": "Vibration Fatigue Testing Machines Including Tensile Testing, Compression Testing & Bending"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B14.00",
      "name": "Testing Machines"
    },
    "Subcategory": {
      "code": "B14.18",
      "name": "Testing Machines, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.01",
      "name": "Accessories for Measuring Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.02",
      "name": "Acquisition of Measured Electrical / Non-electrical Data"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.03",
      "name": "Angle and inclination Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.04",
      "name": "Bore Measuring Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.05",
      "name": "Circularity Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.06",
      "name": "Clamping Force Testers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.07",
      "name": "CMM for Measuring, Scanning and Digitising"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.08",
      "name": "Computerized Measuring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.09",
      "name": "CNC Coordinate Measuring Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.10",
      "name": "Diagnostic Systems and Software"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.11",
      "name": "Digital Read Out Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.12",
      "name": "Displacement and Distance Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.13",
      "name": "Dynamometer"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.14",
      "name": "Electronic Gauging and Measurement Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.15",
      "name": "Equipment for Electromotive Force Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.16",
      "name": "Forcev and Moment Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.17",
      "name": "Gauges & Callipers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.18",
      "name": "Gear Testing Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.19",
      "name": "Hob Testing Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.20",
      "name": "Image Processing Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.21",
      "name": "Laser Measuring Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.22",
      "name": "Layer Thickness Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.23",
      "name": "Limit Signal Transmitters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.24",
      "name": "Measured Data Amplifiers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.25",
      "name": "Measuring and Marking Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.26",
      "name": "Measuring Instruments for Machine Geometry"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.27",
      "name": "Measuring Instruments for Residual Magnetism"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.28",
      "name": "Measuring Instruments, Electrical"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.29",
      "name": "Measuring Instruments, Mechanical"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.30",
      "name": "Measuring Instruments,Electronic"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.31",
      "name": "Measuring Instruments, Optical"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.32",
      "name": "Measuring Instruments,Pneumatic"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.33",
      "name": "Measuring Instruments;others"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.34",
      "name": "Measuring Machines, Any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.35",
      "name": "Measuring Microscopes with Digital Image Processing"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.36",
      "name": "Measuring Tables"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.37",
      "name": "Micrometers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.38",
      "name": "Microscopes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.39",
      "name": "Noise Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.40",
      "name": "Peripherals"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.41",
      "name": "Picture and Signal Pattern Recognition Sensor Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.42",
      "name": "Pressure Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.43",
      "name": "Profile and Shape Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.44",
      "name": "Profile Projector"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.45",
      "name": "Quality Information and Display Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.46",
      "name": "Signal Processing Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.47",
      "name": "Speed Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.48",
      "name": "Stereo Microscopes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.49",
      "name": "Surface Measuring Instruments & Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.50",
      "name": "Surface Plates and Flatness Measuring Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.51",
      "name": "Systems for Tool Identification"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.52",
      "name": "Temperature Measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.53",
      "name": "Testing Control and Management"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.54",
      "name": "Thread measurement"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.55",
      "name": "Transducers (Measuring Probes and Sensors)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.56",
      "name": "Vibration Meters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B15.00",
      "name": "Measuring, Inspection & Quality Control"
    },
    "Subcategory": {
      "code": "B15.57",
      "name": "Video Systems and Endoscopes"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.01",
      "name": "Blow moulding moulds"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.02",
      "name": "Components for dies and moulds; other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.03",
      "name": "Die Sets"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.04",
      "name": "Die-casting dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.05",
      "name": "Drawing Dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.06",
      "name": "Extrusion dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.07",
      "name": "Injection moulding tools for other materials"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.08",
      "name": "Injection moulding tools for thermoplastics"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.09",
      "name": "Moulds for ceramics"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.10",
      "name": "Moulds for extrusion coating"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.11",
      "name": "Moulds for glass"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.12",
      "name": "Moulds for rubber"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.13",
      "name": "Multi-colour injection moulding tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.14",
      "name": "Multi-component injection moulding tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.15",
      "name": "Pressing dies for thermosetting plastics"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.16",
      "name": "Sleeves"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.17",
      "name": "Standard parts for press tools, moulds, dies"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.18",
      "name": "Vacuum Moulds"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B16.00",
      "name": "Dies & Moulds"
    },
    "Subcategory": {
      "code": "B16.19",
      "name": "Dies and moulds, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.01",
      "name": "Data Networks, also for FMS"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.02",
      "name": "Input Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.03",
      "name": "Interactive Terminals"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.04",
      "name": "Operational Data Acquisition"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.05",
      "name": "Peripherals"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B17.00",
      "name": "Production Control & Networks"
    },
    "Subcategory": {
      "code": "B17.06",
      "name": "Production Control & Networks, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.01",
      "name": "Adaptive Controls"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.02",
      "name": "Axis Position Measuring Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.03",
      "name": "CNC for Cutting Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.04",
      "name": "CNC for Forming Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.05",
      "name": "CNC for Other Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.06",
      "name": "Electronic and Stored Program Controls"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.07",
      "name": "Feed Drives"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.08",
      "name": "Linear Motors"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.09",
      "name": "Programmable Controllers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.10",
      "name": "Programming Systems for CNC/PLC"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.11",
      "name": "Robot Controllers"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.12",
      "name": "Spindle Drives"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B18.00",
      "name": "Controls, Drives and Systems"
    },
    "Subcategory": {
      "code": "B18.13",
      "name": "Control and Drive Systems, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.01",
      "name": "ERP"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.02",
      "name": "Software for Design & Development"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.03",
      "name": "Software for Engineering & Methods"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.04",
      "name": "Software for Production Planning & Control"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.05",
      "name": "Software for Programming"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.06",
      "name": "Software for Quality Control & Management"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.07",
      "name": "Software for Simulation"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B19.00",
      "name": "Software"
    },
    "Subcategory": {
      "code": "B19.08",
      "name": "Software, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.01",
      "name": "Design & Engineering"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.02",
      "name": "Heat Treatment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.03",
      "name": "Surface Coating"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.04",
      "name": "Machining & Manufacturing"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.05",
      "name": "Erection & Maintenance, Calibration"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.06",
      "name": "Tool Sharpening & Maintenance"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.07",
      "name": "Research & Documentation"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B20.00",
      "name": "Services"
    },
    "Subcategory": {
      "code": "B20.08",
      "name": "Training & Education"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.01",
      "name": "Lifting & Transport Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.02",
      "name": "Small Electric Hoists for Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.03",
      "name": "Storing of Workpieces and Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.04",
      "name": "Tool Cabinets"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.05",
      "name": "Work Benches, Composable Shelves"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.06",
      "name": "Work Shop Fittings"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B21.00",
      "name": "Shop Equipment"
    },
    "Subcategory": {
      "code": "B21.07",
      "name": "Shop Equipment, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.01",
      "name": "Chip Compacting Presses"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.02",
      "name": "Emulsion breaking"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.03",
      "name": "Equipment for Waste Disposal; others"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.04",
      "name": "Oil and oil mist vacuuming systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.05",
      "name": "Oil Recovery Equipment (Chip Separator)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.06",
      "name": "Oil separation, oil mist separation"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.07",
      "name": "Processing of coolants and lubricants"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.08",
      "name": "Tipping containers for chip removal"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.09",
      "name": "Waste oil collecting tanks"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B22.00",
      "name": "Waste Disposal Equipment"
    },
    "Subcategory": {
      "code": "B22.10",
      "name": "Waste Disposal Equipment, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.01",
      "name": "Air protection systems and components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.02",
      "name": "Dust Separating Systems, Filtering Systems, Filters"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.03",
      "name": "Exhaust systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.04",
      "name": "Fire protection safety systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.05",
      "name": "Industrial safety products"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.06",
      "name": "Industrial suction plants for cleaning up of machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.07",
      "name": "Noise reduction systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.08",
      "name": "Personal safety devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.09",
      "name": "Protection devices for robots"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.10",
      "name": "Safety devices, Splash"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.11",
      "name": "Safety Equipment for Metal Forming Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.12",
      "name": "Safety equipment on the machine"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.13",
      "name": "Wastewater purification, plant and components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.14",
      "name": "Water treatment, plant and components"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.15",
      "name": "Workshop safety devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B23.00",
      "name": "Safety & Environment"
    },
    "Subcategory": {
      "code": "B23.16",
      "name": "Equipment for safety and environment; any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.01",
      "name": "Coolant Filtration Systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.02",
      "name": "Coolant Pumps"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.03",
      "name": "Lubricants and Cutting Fluids for use on Machine Tools"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.04",
      "name": "Lubrication Equipment and Devices"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.05",
      "name": "Minimal lubrification technology (MQL)"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.06",
      "name": "Oil Cleaning and Dispensing System"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.07",
      "name": "Oil mist lubricators"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.08",
      "name": "Oiling and greasing systems"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.09",
      "name": "Separators for Coolants and Lubricants"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.10",
      "name": "Spraying attachment for forging/die casting machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B24.00",
      "name": "Lubrication & Coolant"
    },
    "Subcategory": {
      "code": "B24.11",
      "name": "Lubrication and coolant; any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.01",
      "name": "Welding Fume Exhaust Units"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.02",
      "name": "Welding Material"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.03",
      "name": "Welding Accessories"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.04",
      "name": "Other Welding Equipment"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.05",
      "name": "Thermal Cutting Accessories"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.06",
      "name": "Welding Torches"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.07",
      "name": "Thermal Cutting Torches"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.08",
      "name": "Portable Welding Machines"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.09",
      "name": "Welding Positioners"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.10",
      "name": "Soldering Materials"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.11",
      "name": "Soldering Equipment and Accessories"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B25.00",
      "name": "Welding Material & Accessories"
    },
    "Subcategory": {
      "code": "B25.12",
      "name": "Welding Material & Accessories, any other"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.01",
      "name": "Aluminium and Light Alloys"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.02",
      "name": "Bar, Profile, Sections"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.03",
      "name": "Blanks for Tools-hard Metal"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.04",
      "name": "Blanks for Tools-HSS"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.05",
      "name": "Die & Tool Steel"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.06",
      "name": "Forged Parts"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.07",
      "name": "Galvanized, Pre-coated"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.08",
      "name": "High strength material"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.09",
      "name": "High temperature material"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.10",
      "name": "Industrial Adhesives"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.11",
      "name": "Metal Powders"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.12",
      "name": "Polymer Concrete"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.13",
      "name": "Pre-Treated"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.14",
      "name": "Resins for Models and Rapid Prototyping"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.15",
      "name": "Shape Memory Alloys"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.16",
      "name": "Sheets"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.17",
      "name": "Steel and Special Alloys"
    }
  },
  {
    "MainCategory": {
      "code": "B",
      "name": "Tooltech 2025"
    },
    "Category": {
      "code": "B26.00",
      "name": "Raw Materials"
    },
    "Subcategory": {
      "code": "B26.18",
      "name": "Raw Materials, any other"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.01",
      "name": "Communication & Networking"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.02",
      "name": "Data Acquisition Hardware"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.03",
      "name": "Guided Vehicles, Smart Handling & Transfer Systems"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.04",
      "name": "Humanoid / Cooperating Robots (Cobots)"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.05",
      "name": "Integrating Hardware"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.06",
      "name": "IoT Devices, Sensors and Components"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.07",
      "name": "Smart Mobile Terminals"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.08",
      "name": "Vision Systems"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.09",
      "name": "Industry 4.0 hardware, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.10",
      "name": "Artificial Intelligence"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.11",
      "name": "CAD / CAM / CAE"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.12",
      "name": "Communication"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.13",
      "name": "Data Analytics & Cloud Services"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.14",
      "name": "ERP"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.15",
      "name": "Industry 4.0 Integrated Systems"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.16",
      "name": "Internet and Data Security"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.17",
      "name": "Smart Factory Solutions"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.18",
      "name": "Augmented & Virtual Reality"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.19",
      "name": "Simulation, Digital Twins"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C01.00",
      "name": "Industry 4.0 Hardware"
    },
    "Subcategory": {
      "code": "C01.20",
      "name": "Industry 4.0 Software, anyother"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.01",
      "name": "Additive Manufacturing / 3D Printing Machines"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.02",
      "name": "Fused Deposition Modeling"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.03",
      "name": "Selective Laser Sintering"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.04",
      "name": "Technologies and Services"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.05",
      "name": "CAD / CAE Software in Additive Manufacturing /  3D printing"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.06",
      "name": "Scanning , Digitization, Metrology and Inspection equipment"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.07",
      "name": "Metal Moulding and Tooling"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.08",
      "name": "Hybrid Process"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.09",
      "name": "Input Materials – Powders, Granules, Filaments,Resins (Metal & Plastic)"
    }
  },
  {
    "MainCategory": {
      "code": "C",
      "name": "Digital Manufacturing 2025"
    },
    "Category": {
      "code": "C02.00",
      "name": "Additive Manufacturing"
    },
    "Subcategory": {
      "code": "C02.10",
      "name": "Additive Manufacturing, anyother"
    }
  }
]