import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getErrorResponse, toastError } from "../utils/util";

function SecurityScan() {
  const [scannedUsers, setCurrentUsers] = useState([]);
  const inputRef = useRef();

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  }

  function formatTime(dateString) {
    const time = new Date(dateString);
    return time.toLocaleTimeString("en-US", { hour12: true });
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const scannedValue = event.target.value;
      fetchUserDetails(scannedValue);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };
  const fetchUserDetails = async (bid) => {
    try {
      const { data } = await axios.get(
        `https://apis.expoplanner.in/event/symposium-security-scan?barcode_id=${bid}`
      );
      toast.success(data.message, { autoClose: 1 });
      fetchUsers();
    } catch (error) {
      toast.error(getErrorResponse(error), { autoClose: 1 });
    }
  };
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(
        `https://apis.expoplanner.in/event/get-symposium-scanned-users-show`
      );
      setCurrentUsers(data);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ backgroundColor: "#005670" }}>
        <img
          src="https://www.imtma.in/symposium-visitor-registration/images/bg-head-02.jpg"
          style={{
            display: "block",
            height: "120px",
            borderRadius: "8px",
            margin: "auto",
          }}
        />
      </div>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1280}
        width={"100%"}
        margin="auto"
        mt={2}
        gap={1}
        paddingBottom={1}
        flexGrow={1}
      >
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <CardContent
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <h3>Scanned List</h3>
            <TableContainer
              component={Paper}
              style={{ flexGrow: 1, maxHeight: "192px", overflowY: "auto" }}
            >
              <Table aria-label="scanned list table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Scanned Date</TableCell>
                    <TableCell>Scanned Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scannedUsers?.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row?.title} {row?.first_name} {row?.last_name}{" "}
                        {row?.name}
                      </TableCell>
                      <TableCell>{row?.company}</TableCell>
                      <TableCell>{row?.designation}</TableCell>
                      <TableCell>{formatDate(row.latestScan)}</TableCell>
                      <TableCell>{formatTime(row.latestScan)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <Box style={{ display: "block" }} ml={"auto"} mr={"auto"} mb={4}>
          <input
            ref={inputRef}
            id="barcodeInput"
            type="text"
            onKeyDown={handleKeyDown}
            placeholder="Scan Badge"
            style={{
              padding: "10px",
              fontSize: "16px",
              width: "300px",
              textAlign: "center",
              borderRadius: "4px",
              border: "2px solid #000",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SecurityScan;
