export const HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Banner_Visitor+registration.png";
export const FOOTER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Footer.png";
export const ADS = [
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+1.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+2.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+3.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+4.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+5.png",
];
export const SELECT = "select";
export const TEXT = "text";
export const PHONE = "phone";
export const TEL = "tel";
export const SOCIAL = "social";
export const EMAIL = "email";
export const COUNTRY = "country";
export const STATE = "state";
export const CITY = "city";
export const CHECKBOX = "checkbox";
export const PRODUCT_CATEGORY = "product_category";
export const WIDGET_LIST = [
  SELECT,
  TEXT,
  PHONE,
  TEL,
  SOCIAL,
  EMAIL,
  COUNTRY,
  STATE,
  CHECKBOX,
  PRODUCT_CATEGORY,
];
