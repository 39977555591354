import { Fragment, useState } from "react";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import { Box, SwipeableDrawer } from "@mui/material";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import DynamicForm5 from "./DynamicForm5";
import EmailBuilder from "./EmailBuilder";
import MyEditor from "./quill/MyEditor";
import MyQuillEditor from "./quill/MyQuillEditor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import CategoryBuilder from "./CategoryBuilder";
import SwipeableTemporaryDrawer from "./Demo";
import { StyledEngineProvider } from "@mui/material/styles";
import MiniDrawer from "./SlideHeader";
import DragDroppable from "./DragDroppable";
import RegistrationPage from "./registration/RegistrationPage";
import ExportSymposium from "./exportSymposium";
import SecurityScan from "./symposium/SecurityScan";

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/email" element={<EmailBuilder />} />
          <Route path="/editor" element={<DynamicForm5 />} />
          <Route path="/test-dnd" element={<DragDroppable />} />
          <Route path="/visitor-registration" element={<RegistrationPage />} />
          <Route path="/export-symposium-users" element={<ExportSymposium />} />
          <Route path="/symposium-security-scan" element={<SecurityScan />} />
          <Route path="/demo" element={<SwipeableTemporaryDrawer />} />
          <Route path="/slide-draw" element={<MiniDrawer />} />
          <Route path="/category-builder" element={<CategoryBuilder />} />
          <Route path="/email-template-builder" element={<MyQuillEditor />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
