// useCities.ts
import { useEffect, useState } from 'react';

export interface City {
  name: string
}

const useCities = (countryCode: string | null, stateCode: string | null) => {
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!countryCode || !stateCode) return;

    const fetchCities = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://apis.expoplanner.in/visitor/get-cities/${countryCode}/${stateCode}`);
        const data = await response.json();
        setCities(data);
      } catch (error) {
        console.error('Failed to fetch cities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, [countryCode, stateCode]);

  return { cities, loading };
};

export default useCities;
